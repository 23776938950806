import React, { useEffect } from 'react';
import SILENT from 'assets/icons/volume-minus-icon.svg';
import * as Styled from './Dispositions.styled';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { useEditorEvents } from 'shared/components/ui/editor/editor/useEditorEvents';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

interface ISilentDisposition {
  silentDisposition: DispositionNames;
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
  sendTerminateCall: (reason: ReasonCodes) => void;
}
const SILENCE_QUICKWORD_CODE = 'sln';

const SilentDisposition = ({
  silentDisposition,
  setSelectedDisposition,
  cancelDisposition,
  sendTerminateCall,
}: ISilentDisposition) => {
  const { appendCaption } = useEditorEvents();
  const timerMinutes =
    silentDisposition === DispositionNames.SilentMidCall ? 5 : 1;
  const timerSeconds = 60 * timerMinutes;
  const quickwords = useSelector(
    (state: RootState) => state.quickwords.quickwords
  );

  useEffect(() => {
    appendCaption(quickwords[SILENCE_QUICKWORD_CODE] ?? '');
  }, []);

  const timerExpired = () => {
    sendTerminateCall(ReasonCodes.Silent);
  };

  const canceledTimer = () => {
    cancelDisposition();
    setSelectedDisposition(DispositionNames.Normal);
  };

  return (
    <Styled.DispositionContainer>
      <Styled.DispositionHeaderContainer>
        <Styled.SVGDispositionIcon src={SILENT} />
        <Styled.DispositionIconLabel>Silent</Styled.DispositionIconLabel>
      </Styled.DispositionHeaderContainer>
      <Styled.DispositionsLabelContainer>
        <Styled.TextOnly>Call Ending in</Styled.TextOnly>
      </Styled.DispositionsLabelContainer>
      <Timer
        seconds={timerSeconds}
        timerExpired={timerExpired}
        canceledTimer={canceledTimer}
        footerText="Cancel if audio resumes"
      />
    </Styled.DispositionContainer>
  );
};

export default SilentDisposition;
