import { useContext, useEffect } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { QueueScreen } from 'shared/components/ui/queue-screen/queue-screen.component';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useSendDataScienceEventMessage,
  BillingSessionStates,
} from 'shared/hooks/data-science-api/use-send-data-science-event-message.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { useOAuth } from 'modules/authentication/oauth.context';
import { UserStates } from 'state/userSessionState/userStates';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';

const AgentQueue = () => {
  const { setShowQueue, handleQueueCancel } = useContext(LandingPageContext);
  const { sendNotAcceptingCalls } = useContext(IPCTSSessionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { sendSessionUpdateEventMessage } = useSendDataScienceEventMessage();
  const { accessToken } = useOAuth();
  const user = useSelector((state: RootState) => state.user.value);
  const userTransitionState = useSelector((state: RootState) => state.userSessionState.transitionState);
  const { updateUserState } = useUserState();

  const handleCancelQueue = () => {
    const DesktopVersion = localStorage.getItem('DesktopVersion') || '';
    sendSessionUpdateEventMessage(
      accessToken,
      user.sessionLoginId,
      user.employeeID,
      BillingSessionStates.LeaveQueue,
      user.agentEmail,
      DesktopVersion
    );
    sendNotAcceptingCalls('break', 'Out to lunch');
    navigate('/agent-lobby');
    setShowQueue(false);
    handleQueueCancel();
  };

  useEffect(() => {
    const DesktopVersion = localStorage.getItem('DesktopVersion') || '';
    let from = location.state?.from?.pathname;
    const fromAgentLobby = from.includes('agent-lobby');
    if(fromAgentLobby) {
      updateUserState(UserStates.IN_QUEUE);
      sendSessionUpdateEventMessage(
        accessToken,
        user.sessionLoginId,
        user.employeeID,
        BillingSessionStates.JoinQueue,
        user.agentEmail,
        DesktopVersion
      );
    }
  }, []);

  useEffect(() => {
    const DesktopVersion = localStorage.getItem('DesktopVersion') ?? '';
    let from = location.state?.from?.pathname;
    const fromAgentLobby = from.includes('agent-lobby');
    if(!fromAgentLobby) {
      const billingState = userTransitionState === UserStates.CALL_DELAY
        ? BillingSessionStates.CallDelay
        : BillingSessionStates.WaitingForNextCall;
      updateUserState(userTransitionState);
      sendSessionUpdateEventMessage(
        accessToken,
        user.sessionLoginId,
        user.employeeID,
        billingState,
        user.agentEmail,
        DesktopVersion
      );
    }
  }, [userTransitionState]);

  return <QueueScreen onCancelQueue={handleCancelQueue} />;
};

export default AgentQueue;
