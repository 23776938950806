import * as React from 'react';
import { useEffect } from 'react';
import { ContentEditable } from './ContentEditable';
import { useEditorEvents } from './useEditorEvents';
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import '../styles.css';

export function Editor({ editable }: EditorProps) {
  const containerRef = React.useRef<any>();

  const { handleBlur, handleKeyDown, handleKeyup, handleClick, handleMouseUp } =
    useEditorEvents();

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  });

  function onClickOutside(event: MouseEvent) {
    if (event.target === containerRef.current) {
      return;
    }

    if (containerRef.current!.contains(event.target as HTMLElement)) {
      return;
    }
    if (handleBlur) {
      handleBlur();
    }
  }
  const handleOnPaste = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }  
  return (
    <div ref={containerRef} className="rsw-editor" tabIndex={0}>
      <ContentEditable
        contentEditable={editable!}
        id={'rsw-cts-editor'}
        className="rsw-cts"
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyup}
        onClick={handleClick}
        onMouseUp={handleMouseUp}
        onPaste={handleOnPaste}
      />
    </div>
  );
}

export interface EditorProps {
  editable?: boolean;
}
