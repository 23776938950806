import { Outlet } from 'react-router-dom';
import * as Styled from './AppLayout.styled';
import DesktopHeader from 'components/AppHeader';
import { useElectronContextBridge } from 'shared/hooks/use-electron-context-bridge';

const AppLayout = () => {
  useElectronContextBridge();

  return (
    <Styled.MainLayout
      style={{
        minHeight: '100%',
        display: 'inline-block',
        width: '100%',
      }}
      className="test"
    >
      <DesktopHeader />
      <Styled.CustomLayout>
        <Styled.CustomLayoutContent>
          <Styled.CustomContent>
            <Outlet />
          </Styled.CustomContent>
        </Styled.CustomLayoutContent>
      </Styled.CustomLayout>
    </Styled.MainLayout>
  );
};

export default AppLayout;
