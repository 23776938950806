/* eslint-disable react/prop-types */

import {
  HTMLAttributes,
  memo,
  SyntheticEvent,
  useEffect,
} from 'react';
import { ShardParagraph } from 'shared/components/ui/editor/editor/shardParagraph';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import {trimCaptions } from 'state/captions/captionsSlice';
import CONSTANTS from 'shared/utils/constants';
import logger from 'services/logger';

function blurContentEditable(event: any) {
  event.preventDefault();
  event.stopPropagation();
}

export const ContentEditable = memo(function ContentEditable({
  className,
  disabled,
  tagName,
  editorRef,
  contentEditable = true,
  ...rest
}: ContentEditableProps) {
  const { onFocus, ...editorProps } = rest;
  const captions = useSelector((state: RootState) => state.captions.value);
  const dispatch = useDispatch();

  useEffect(() => {
    if((Object.keys(captions)?.length || 0) >= CONSTANTS.WORD_LIMIT){
      const container = document.getElementById('rsw-cts-editor');
      const outerWidth = container?.offsetWidth || 0;
      let totalWidth = 0;
      let previoudTag;
      let isFirst = true;
      for(let childNode of container?.children || []){
        if(childNode instanceof HTMLElement && childNode?.tagName === 'P'){
          if(isFirst){
            totalWidth += childNode?.offsetLeft;
            isFirst = false;
          }
          let shardId;
          totalWidth += childNode?.offsetWidth
          if(totalWidth > outerWidth){
            let wordId;
            // we have a wrapping shard
            // 
            if(childNode.offsetLeft < outerWidth && childNode?.children.length > 1){

              shardId = childNode?.attributes?.getNamedItem('data-shardid')?.value;
              let previousWord;
              totalWidth -= childNode?.offsetWidth;
              for(let word of childNode?.children || []){
                if(word instanceof HTMLElement && word?.tagName === 'SPAN'){
                  totalWidth += word?.offsetWidth;
                  if(totalWidth > outerWidth){
                    wordId = previousWord?.attributes?.getNamedItem('data-wordid')?.value;
                    break;
                  }
                  previousWord = word;
                }
              }
            } else {
              shardId = previoudTag?.attributes?.getNamedItem('data-shardid')?.value;
            }

            if(shardId){
              logger.info({
                methodName: `** ContentEditable useEffect trim to shard [${previoudTag?.innerText}]`,
              }, false);
              dispatch(trimCaptions({shardId, wordId}));
            }
            break;
          }
          previoudTag = childNode;
        }
      }
    }
  }, [captions]);
  
  return (
    <div
      contentEditable={contentEditable}
      suppressContentEditableWarning={true}
      onBlur={blurContentEditable}
      onDragStart={e => {
        e.preventDefault();
        e.stopPropagation();
      }} 
      {...editorProps}
      className={className}
    >
      {Object.values(captions)?.map((item, index) => {
        return <ShardParagraph key={`shard-${item.shardId}`} {...item} />
      })}
    </div>
  );
});

export type ContentEditableEvent = SyntheticEvent<any, Event> & {
  target: { name?: string; value: string };
};

export interface ContentEditableProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  name?: string;
  onChange?: (event: ContentEditableEvent) => void;
  onClick: (event: any) => void;
  tagName?: string;
  editorRef?: any;
  isCTS?: boolean;
  contentEditable: boolean;
}
