import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export const HandoffScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`;

export const HandoffScreenMiddleWidget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HandoffScreenArrowIcon = styled(SVG)`
  height: 60px;
  width: 60px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  path {
    fill: ${({ theme }) => theme.colors.iconColorTwo} !important;
  }
`;

export const HandoffScreenDottedBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  gap: 32px;
  width: 118px;
  height: 118px;
  border: 2px dashed ${({ theme }) => theme.colors.fontColor} !important;
  border-radius: 12px;
  background: #ffffff0d;
  margin-bottom: 28px;
`;

export const HandoffScreenTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.fontColor} !important;
  line-height: 36px;
  margin-bottom: 8px;
`;

export const HandoffScreenText = styled.p`
  font-size: 19px;
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  color: rgba(255, 255, 255, 0.55);
`;
