/* eslint-disable no-lonely-if */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import * as StyledControls from 'modules/ipcts-call-session/components/controls/call-controls.styled';
import { HANDOFFS } from 'modules/ipcts-call-session/components/controls/context/call-handoff.types';
import Loader from 'modules/ipcts-call-session/components/controls/loader.component';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import * as Styled from './call-handoff.styled';
import DefaultHandoffs from 'components/Handoffs';

const APPROVAL_REQUIRED_SECONDS = 600; //For Demo set to 10
const NO_APPROVAL_REQUIRED_SECONDS = 1200; //For Demo set to 20
const HOUR_SECODS = 3600; //For Demo set to 60

type CallHandoffRequestData = {
  Language: string;
  CallId: string;
  AgentId: string;
  IsVirtual: boolean;
  CurrentCallDuration: number;
  IsLanguageHandoff: boolean;
  CreatedDt: string;
};

type CallHandoffCancelData = {
  HandoffExecutionArn: string;
  HandoffId: string;
  AgentId: string;
  CreatedDt: string;
};

interface ICallHandoffProps {
  addCustomCaption: (text: string, editable?: boolean) => void;
  chronometerCounter: number;
  handoffCallEnd: () => void;
  tabsHidden: boolean;
  setTabsHidden: React.Dispatch<React.SetStateAction<boolean>>;
  setHandoffType: React.Dispatch<React.SetStateAction<string | null>>;
}

const CallHandoff = ({
  chronometerCounter,
  addCustomCaption,
  handoffCallEnd,
  tabsHidden,
  setTabsHidden,
  setHandoffType,
}: ICallHandoffProps) => {
  // const { agentId, requestedHandoff, setRequestedHandoff } =
  //   React.useContext(LandingPageContext);
  // const handoffRequestData = React.useRef<CallHandoffRequestData>({
  //   Language: 'English',
  //   CallId: '',
  //   AgentId: '',
  //   IsVirtual: false,
  //   CurrentCallDuration: 0,
  //   IsLanguageHandoff: false,
  //   CreatedDt: '',
  // });
  // const handoffCancelData = React.useRef<CallHandoffCancelData>({
  //   HandoffExecutionArn: '',
  //   HandoffId: '',
  //   AgentId: '',
  //   CreatedDt: '',
  // });

  // const [isWaitingHandoffRequest, setIsWaitingHandoffRequest] =
  //   React.useState(false);
  // const [isHandoffRequestApproved, setIsHandoffRequestApproved] =
  //   React.useState(false);
  // const [isReadyForHandoff, setIsReadyForHandoff] = React.useState(false);
  // const [englishSpanishHandoffTimer, setEnglishSpanishHandoffTimer] =
  //   React.useState(35);

  // const isDisabledByTimer = React.useMemo(() => {
  //   if (chronometerCounter < APPROVAL_REQUIRED_SECONDS + 1) {
  //     return true;
  //   }
  //   return false;
  // }, [chronometerCounter]);

  // const isApprovalRequired = React.useMemo(() => {
  //   if (
  //     chronometerCounter > APPROVAL_REQUIRED_SECONDS &&
  //     chronometerCounter < NO_APPROVAL_REQUIRED_SECONDS + 1
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [chronometerCounter]);

  // const isHandOffDisabled = React.useMemo(() => {
  //   if (chronometerCounter > NO_APPROVAL_REQUIRED_SECONDS + 1) {
  //     return true;
  //   }
  //   return false;
  // }, [chronometerCounter]);

  // const isDisabledUntilHour = React.useMemo(() => {
  //   if (chronometerCounter > HOUR_SECODS + 1) {
  //     return true;
  //   }
  //   return false;
  // }, [chronometerCounter]);

  // const sendRequestHandoff = () => {};

  // React.useEffect(() => {
  //   if (isWaitingHandoffRequest && englishSpanishHandoffTimer > 0) {
  //     setEnglishSpanishHandoffTimer(englishSpanishHandoffTimer - 1);
  //   }
  //   if (englishSpanishHandoffTimer === 0) {
  //     setIsReadyForHandoff(true);
  //   }
  // }, [chronometerCounter]);

  // React.useEffect(() => {
  //   if (
  //     requestedHandoff === HANDOFFS.ENGLISH_SPANISH.LABEL &&
  //     isWaitingHandoffRequest
  //   ) {
  //     addCustomCaption('Speaking Spanish');
  //     handoffRequestData.current = {
  //       ...handoffRequestData.current,
  //       Language: 'Spanish',
  //       IsLanguageHandoff: true,
  //     };
  //   }
  // }, [isWaitingHandoffRequest]);

  // React.useEffect(() => {
  //   if (
  //     requestedHandoff === HANDOFFS.ENGLISH_SPANISH.LABEL &&
  //     isReadyForHandoff
  //   ) {
  //     addCustomCaption(`Verify 90% of the call is in Spanish.`);

  //     addCustomCaption('Verifique que el 90% de la llamada sea en español.');
  //   }
  // }, [isReadyForHandoff]);

  // const simulateReadyForHandoffTimeout = (seconds: number) => {
  //   setTimeout(() => {
  //     setIsReadyForHandoff(true);
  //   }, seconds * 1000);
  // };

  // const cancelHandoffRequest = () => {
  //   setIsWaitingHandoffRequest(false);
  //   setIsReadyForHandoff(false);
  //   setRequestedHandoff('');
  //   setTabsHidden(false);

  // };

  // const cancelLanguageHandoffRequest = () => {
  //   setIsWaitingHandoffRequest(false);
  //   setIsReadyForHandoff(false);
  //   setRequestedHandoff('');
  //   setEnglishSpanishHandoffTimer(35);
  //   setTabsHidden(false);
  // };

  // const completeHandoffRequest = () => {
  //   sendRequestHandoff();
  //   handoffCallEnd();
  // };

  // const showRequestingView = (selected: string) => {
  //   const flows = {
  //     [HANDOFFS.ENGLISH_SPANISH.ID]: () => {
  //       setRequestedHandoff(HANDOFFS.ENGLISH_SPANISH.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setTabsHidden(true);
  //     },
  //     [HANDOFFS.EMERGENCY.ID]: () => {
  //       setRequestedHandoff(HANDOFFS.EMERGENCY.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setTabsHidden(true);
  //     },
  //     [HANDOFFS.BREAK_LUNCH.ID]: () => {
  //       setRequestedHandoff(HANDOFFS.BREAK_LUNCH.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setIsHandoffRequestApproved(true);
  //       setTabsHidden(true);
  //       if (!isApprovalRequired) setIsReadyForHandoff(true);
  //     },
  //     [HANDOFFS.MEETING.ID]: () => {
  //       sendRequestHandoff();
  //       setRequestedHandoff(HANDOFFS.MEETING.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setIsHandoffRequestApproved(true);
  //       setTabsHidden(true);
  //       if (!isApprovalRequired) setIsReadyForHandoff(true);
  //     },
  //     [HANDOFFS.TRAINING.ID]: () => {
  //       sendRequestHandoff();
  //       setRequestedHandoff(HANDOFFS.TRAINING.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setIsHandoffRequestApproved(true);
  //       setTabsHidden(true);
  //       if (!isApprovalRequired) setIsReadyForHandoff(true);
  //     },
  //     [HANDOFFS.CONFERENCE_CALL.ID]: () => {
  //       sendRequestHandoff();
  //       setRequestedHandoff(HANDOFFS.CONFERENCE_CALL.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setIsHandoffRequestApproved(true);
  //       setTabsHidden(true);
  //       if (!isApprovalRequired) setIsReadyForHandoff(true);
  //     },
  //     [HANDOFFS.END_OF_SHIFT.ID]: () => {
  //       sendRequestHandoff();
  //       setRequestedHandoff(HANDOFFS.END_OF_SHIFT.LABEL);
  //       setIsWaitingHandoffRequest(true);
  //       setIsHandoffRequestApproved(true);
  //       setTabsHidden(true);
  //       if (!isApprovalRequired) setIsReadyForHandoff(true);
  //     },
  //   };
  //   const result = flows[selected];
  //   if (!result) {
  //     return;
  //   }
  //   result();
  // };

  // const handoffMenu = () => (
  //   <Styled.HandoffButtonsContainer>
  //     <Styled.HandoffOptionButton
  //       id="spanishSpeakerButton"
  //       onClick={() => showRequestingView(HANDOFFS.ENGLISH_SPANISH.ID)}
  //     >
  //       <Styled.ButtonText>{HANDOFFS.ENGLISH_SPANISH.LABEL}</Styled.ButtonText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="emergencyButton"
  //       onClick={() => showRequestingView(HANDOFFS.EMERGENCY.ID)}
  //     >
  //       <Styled.ButtonText>{HANDOFFS.EMERGENCY.LABEL}</Styled.ButtonText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="breakButton"
  //       onClick={() => showRequestingView(HANDOFFS.BREAK_LUNCH.ID)}
  //       disabled={!isHandOffDisabled}
  //     >
  //       <Styled.ButtonText isDisabled={!isHandOffDisabled}>
  //         {HANDOFFS.BREAK_LUNCH.LABEL}
  //       </Styled.ButtonText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="meetingButton"
  //       onClick={() => showRequestingView(HANDOFFS.MEETING.ID)}
  //       disabled={isDisabledByTimer}
  //     >
  //       <Styled.ButtonText isDisabled={isDisabledByTimer}>
  //         {HANDOFFS.MEETING.LABEL}
  //       </Styled.ButtonText>
  //       <Styled.ButtonSubText hidden={!isApprovalRequired}>
  //         Approval required
  //       </Styled.ButtonSubText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="trainingButton"
  //       onClick={() => showRequestingView(HANDOFFS.TRAINING.ID)}
  //       disabled={isDisabledByTimer}
  //     >
  //       <Styled.ButtonText isDisabled={isDisabledByTimer}>
  //         {HANDOFFS.TRAINING.LABEL}
  //       </Styled.ButtonText>
  //       <Styled.ButtonSubText hidden={!isApprovalRequired}>
  //         Approval required
  //       </Styled.ButtonSubText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="confCallButton"
  //       onClick={() => showRequestingView(HANDOFFS.CONFERENCE_CALL.ID)}
  //       disabled={!isDisabledUntilHour}
  //     >
  //       <Styled.ButtonText isDisabled={!isDisabledUntilHour}>
  //         {HANDOFFS.CONFERENCE_CALL.LABEL}
  //       </Styled.ButtonText>
  //     </Styled.HandoffOptionButton>
  //     <Styled.HandoffOptionButton
  //       id="endShiftButton"
  //       onClick={() => showRequestingView(HANDOFFS.END_OF_SHIFT.ID)}
  //       disabled={!isHandOffDisabled}
  //     >
  //       <Styled.ButtonText isDisabled={!isHandOffDisabled}>
  //         {HANDOFFS.END_OF_SHIFT.LABEL}
  //       </Styled.ButtonText>
  //     </Styled.HandoffOptionButton>
  //   </Styled.HandoffButtonsContainer>
  // );

  // const handoffRequestingView = () => (
  //   <Styled.HandoffContainer>
  //     {isHandoffRequestApproved ? (
  //       <Styled.HandoffParagraphText style={{ marginTop: '17px' }}>
  //         {requestedHandoff} request Approved,
  //         <br />
  //         seeking next CA.
  //       </Styled.HandoffParagraphText>
  //     ) : (
  //       <Styled.HandoffParagraphText style={{ marginTop: '90px' }}>
  //         Requesting {requestedHandoff}.
  //         <br />
  //         Thank you for your patience.
  //       </Styled.HandoffParagraphText>
  //     )}
  //     <Spin
  //       indicator={
  //         <LoadingOutlined style={{ fontSize: 48, color: '#95C940' }} spin />
  //       }
  //     />
  //     <Styled.HandoffConfirmButton
  //       disabled
  //       style={{ marginTop: '90px' }}
  //       onClick={() => completeHandoffRequest()}
  //     >
  //       Handoff to next CA
  //     </Styled.HandoffConfirmButton>
  //     <Styled.CancelHandoffButton
  //       style={{ marginTop: '22px' }}
  //       onClick={() => cancelHandoffRequest()}
  //     >
  //       Cancel
  //     </Styled.CancelHandoffButton>
  //   </Styled.HandoffContainer>
  // );

  // const handoffReadyView = () => (
  //   <Styled.HandoffContainer>
  //     <Styled.HandoffParagraphText
  //       id="readyForHandoffText"
  //       style={{ marginTop: '17px' }}
  //     >
  //       Ready for handoff!
  //     </Styled.HandoffParagraphText>
  //     <Spin
  //       indicator={
  //         <LoadingOutlined style={{ fontSize: 48, color: '#95C940' }} spin />
  //       }
  //     />
  //     <Styled.HandoffConfirmButton
  //       id="handoffConfirmButton"
  //       style={{ marginTop: '90px' }}
  //       onClick={() => completeHandoffRequest()}
  //     >
  //       Handoff to next CA
  //     </Styled.HandoffConfirmButton>
  //     <Styled.CancelHandoffButton
  //       id="handoffCancelButton"
  //       style={{ marginTop: '22px' }}
  //       onClick={() => cancelHandoffRequest()}
  //     >
  //       Cancel
  //     </Styled.CancelHandoffButton>
  //   </Styled.HandoffContainer>
  // );

  // const timerFormat = (seconds: number) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  //   return `${minutes}:${formattedSeconds}`;
  // };

  // const handoffEnglishSpanishVerify = () => (
  //   <Styled.HandoffContainer>
  //     <Styled.LanguageHandoffParagraphText
  //       style={{ marginBottom: '48px', textAlign: 'left', fontSize: '18px' }}
  //     >
  //       Verify 90% of the call is in Spanish.
  //     </Styled.LanguageHandoffParagraphText>

  //     <Styled.LanguageHandoffTimer id="spanishTimer">
  //       {timerFormat(englishSpanishHandoffTimer)}
  //     </Styled.LanguageHandoffTimer>
  //     <br />
  //     <Loader durationInSeconds={35} type="big-loader" />
  //     <Styled.CancelHandoffButton
  //       id="spanishCancelButton"
  //       style={{ marginTop: '30px' }}
  //       onClick={() => cancelLanguageHandoffRequest()}
  //     >
  //       Cancel
  //     </Styled.CancelHandoffButton>
  //   </Styled.HandoffContainer>
  // );

  // const currentView = () => {
  //   if (requestedHandoff !== HANDOFFS.ENGLISH_SPANISH.LABEL) {
  //     if (isWaitingHandoffRequest && !isReadyForHandoff) {
  //       simulateReadyForHandoffTimeout(10);
  //       return handoffRequestingView();
  //     } else if (isWaitingHandoffRequest && isReadyForHandoff) {
  //       return handoffReadyView();
  //     } else {
  //       return handoffMenu();
  //     }
  //   } else {
  //     if (isWaitingHandoffRequest && !isReadyForHandoff) {
  //       return handoffEnglishSpanishVerify();
  //     } else if (isWaitingHandoffRequest && isReadyForHandoff) {
  //       return handoffReadyView();
  //     } else {
  //       return handoffMenu();
  //     }
  //   }
  // };

  return (
    <StyledControls.CallControlsBody tabsHidden={tabsHidden}>
      <DefaultHandoffs
        callDuration={chronometerCounter}
        setHandoffType={setHandoffType}
      />
      ;
    </StyledControls.CallControlsBody>
  );
};

export default CallHandoff;
