import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Button, Layout } from 'antd';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

type CheckScreenSize = {
  isDesktop?: boolean;
};

export const HeaderContainer = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.colors.transparent} !important;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
`;

export const DesktopHeaderContainer = styled(HeaderContainer)`
  justify-content: end;
  height: 36px;
  margin-top: 24px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
`;

export const MobileHeaderContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
`;

export const HeaderLogoText = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_bold} !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  width: 380px;
  color: ${({ theme }) => theme.colors.colorText} !important;
`;

export const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
`;
export const HeaderLogotype = styled(SVG)`
  display: block;
  margin: 35px 0px 35px 24px;
  width: 44.5px;
  height: 44.5px;
  margin-right: 16px;

  path {
    fill: ${({ theme }) => theme.colors.iconColor} !important;
  }
`;

export const HeaderLogoMobile = styled(MenuOutlined)`
  color: ${({ theme }) => theme.colors.white};
  &.anticon-menu {
    svg {
      padding-left: 18px;
      display: block;
      width: auto;
      height: 36px;
    }
  }
`;

export const LeftContainer = styled.div`
  float: left;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UserIcon = styled(UserOutlined)`
  font-size: ${({ theme }) => theme.fontSizes['4xl']} !important;
  color: ${({ theme }) => theme.colors.primaryColorBase};
  background-color: #919496;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  margin-left: 10px;
`;

export const RightMobileContainer = styled.div`
  align-self: center;
  text-align: right;
  float: right;
  height: 100px;
  margin: 18px 16px 18px 0px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: flex;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 24px;
  margin: 0px 24px 0px 0px;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const UserInfoContainer = styled.div`
  margin: 0;
`;

export const Name = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  line-height: 1;
  color: ${({ theme }) => theme.colors.fontColor};
  margin: 0;
`;

export const Username = styled.div`
  line-height: 1;
  color: ${({ theme }) => theme.colors.colorText};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.inter_regular};
`;

export const AvatarIcon = styled(Avatar)<CheckScreenSize>`
  .ant-avatar-string {
    font-size: ${(props) => (props.isDesktop ? '20px' : '16px')};
  }
  background-color: ${({ theme }) => theme.colors.midnightBlue400};
  color: ${({ theme }) => theme.colors.white};
  margin: 0px 0px 0px 16px;
`;

export const NavBarSider = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.colors.primaryColorComplement};
  height: 100% !important;
`;

export const LogoutButton = styled(Button)`
  display: flex;
  justify-content: center;
  background-color: #28181a;
  padding: 8px 0;
  border: none;
  border-radius: 6px;
  height: 36px;
  width: 135px;
  color: #ff5247;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 14px;
  &:hover,
  :focus {
    background-color: #28181a!important;
    color: #ff5247!important;
  }
`;

export const LogoutSVG = styled(SVG)`
  height: 16px;
  width: 16px;
  margin: 4px 8px 0 0;
`;
