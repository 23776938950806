import Background from 'assets/icons/background.svg';
import Hourglass from 'assets/icons/hourglass.svg';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import React, { useContext, useEffect } from 'react';
import * as Styled from 'shared/components/ui/queue-screen/queue-screen.components.styled';
import useAudio from 'shared/hooks/use-audio.hook';

interface IQueueScreenProps {
  onCancelQueue: () => void;
}

export const QueueScreen: React.FunctionComponent<IQueueScreenProps> = ({
  onCancelQueue,
}) => {
  const { showQueue, setIs911Call, is911Call, setIsCallActive } =
    useContext(LandingPageContext);
  const { play } = useAudio('/audios/beep.mp3');

  const [emergencyTimerSeconds, setEmergencyTimerSeconds] = React.useState(5);

  const handdleStartCall = () => {
    play();
    setIsCallActive(true);
  };

  const checkForACall = async () => {};

  useEffect(() => {
    setEmergencyTimerSeconds(5);
    setIs911Call(false);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkForACall();
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkForACall]);

  return (
    <>
      <div>
        <Styled.Background src={Background}> </Styled.Background>
      </div>
      <Styled.QueueContainer>
        {is911Call ? (
          <Styled.Hourglass911Container>
            <div>
              <Styled.CountdownText911>
                {emergencyTimerSeconds}
              </Styled.CountdownText911>
            </div>
          </Styled.Hourglass911Container>
        ) : (
          <Styled.HourglassContainer>
            <div>
              <Styled.HourglassIcon src={Hourglass} />
            </div>
          </Styled.HourglassContainer>
        )}

        {is911Call ? (
          <Styled.QueueText>Entering Emergency Call</Styled.QueueText>
        ) : (
          <Styled.QueueText>You’re in queue for a call</Styled.QueueText>
        )}

        {is911Call ? (
          <Styled.QueueTimer>
            <Styled.ConnectingText911>Connecting...</Styled.ConnectingText911>
          </Styled.QueueTimer>
        ) : (
          <Styled.QueueTimer>
            <Styled.ChronometerContainer
              id="queueTimer"
              inQueue
              timeWrapperProps={{
                color: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                marginBottom: '9.5px',
                fontSize: '19px',
              }}
            />
          </Styled.QueueTimer>
        )}
        <Styled.QueueCancelButton id="cancelButton" onClick={onCancelQueue}>
          Cancel
        </Styled.QueueCancelButton>
      </Styled.QueueContainer>
    </>
  );
};
