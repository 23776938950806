import React, { useEffect } from 'react';
import * as Styled from './Dispositions.styled';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { useEditorEvents } from 'shared/components/ui/editor/editor/useEditorEvents';
import HEADPHONES from 'assets/icons/headphones-menu.svg';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

interface ISpecialToneDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
}
const SPECIAL_TONE_CODE = 'sptn';

const SpecialToneDisposition = ({
  setSelectedDisposition,
  cancelDisposition,
}: ISpecialToneDisposition) => {
  const { appendCaption } = useEditorEvents();
  const quickwords = useSelector(
    (state: RootState) => state.quickwords.quickwords
  );

  useEffect(() => {
    appendCaption(quickwords[SPECIAL_TONE_CODE] ?? '');
  }, []);

  return (
    <Styled.DispositionContainer>
      <Styled.DispositionHeaderContainer>
        <Styled.SVGDispositionIcon src={HEADPHONES} />
        <Styled.DispositionIconLabel>Special Tone</Styled.DispositionIconLabel>
      </Styled.DispositionHeaderContainer>
      <Styled.DispositionsLabelsContainer>
        <Styled.TextOnly>Wait for Disconnect</Styled.TextOnly>
      </Styled.DispositionsLabelsContainer>
      <Styled.CancelButton
        onClick={() => {
          cancelDisposition();
          setSelectedDisposition(DispositionNames.Normal);
        }}
      >
        Cancel
      </Styled.CancelButton>
    </Styled.DispositionContainer>
  );
};

export default SpecialToneDisposition;
