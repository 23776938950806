import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserStates } from './userStates';

interface UserState {
  current: UserStates,
  transitionState: UserStates,
}

const initialState: UserState = {
  current: UserStates.OFFLINE,
  transitionState: UserStates.OFFLINE,
}

const userSessionStateSlice = createSlice({
  name: "userSessionState",
  initialState,
  reducers: {
    setUserSessionState: (state, action: PayloadAction<UserStates>) => {
      state.current = action.payload
    },
    setTransitionState: (state, action: PayloadAction<UserStates>) => {
      state.transitionState = action.payload;
    }
  }
})

export const { setUserSessionState, setTransitionState } = userSessionStateSlice.actions;

export default userSessionStateSlice.reducer;