import ILLEGITIMATE from 'assets/icons/illegitimate-icon.svg';
import PHONE_CALL from 'assets/icons/phone-call-01.svg';
import LANGUGAGE from 'assets/icons/language-icon.svg';
import NO_ANSWER from 'assets/icons/no-answer-icon.svg';
import SILENT from 'assets/icons/volume-minus-icon.svg';
import HEADPHONES from 'assets/icons/headphones-menu.svg';

import * as Styled from './Dispositions.styled';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';


interface IDefaultDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  sendCallDisposition: (disposition: DispositionNames) => void;
  sendSilentDisposition: (receviedNonSilentCaption: boolean) => DispositionNames;
}

const DefaultDisposition = ({
  setSelectedDisposition,
  sendCallDisposition,
  sendSilentDisposition,
}: IDefaultDisposition) => {
  const quickwords = useSelector(
    (state: RootState) => state.quickwords.quickwords
  );
  const functionKeys = useSelector((state: RootState) => state.quickwords.functionKeys);
  const allQuickwords = [...Object.values(quickwords), ...Object.values(functionKeys)].join('|');

  const containsNonSilentCaption = (transcriptText: string) => {
    transcriptText = transcriptText.replaceAll('·', '');
    transcriptText = transcriptText.replaceAll(zeroWidthJoiner.space, '')
    transcriptText = transcriptText.replaceAll(new RegExp(`${allQuickwords}`, 'g'), '');
    transcriptText = transcriptText.replaceAll('()', '');
    transcriptText = transcriptText.trim();
    if(transcriptText.length){
      console.log('containsNonSilentCaption returning true for [', transcriptText, ']');
    }
    return transcriptText.length > 0;
  };

  
  const handleClick = (disposition: DispositionNames) => {
    sendCallDisposition(disposition);
    setSelectedDisposition(disposition);
  };
  const handleClickSilent = () => {
    const transcriptText = document?.getElementById('rsw-cts-editor')?.textContent || '';
    let receviedNonSilentCaption = containsNonSilentCaption(transcriptText);
    const disposition = sendSilentDisposition(receviedNonSilentCaption);
    setSelectedDisposition(disposition);
  };

  return (
    <>
      <Styled.DispositionOption
        id="Busy"
        onClick={() => handleClick(DispositionNames.Busy)}
      >
        <Styled.SVGIcon src={PHONE_CALL} />
        <Styled.ButtonText>Busy</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Illegit"
        onClick={() => handleClick(DispositionNames.Illegit)}
      >
        <Styled.SVGIcon src={ILLEGITIMATE} />
        <Styled.ButtonText>Illegitimate</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Language"
        onClick={() => handleClick(DispositionNames.OthLang)}
      >
        <Styled.SVGIcon src={LANGUGAGE} />
        <Styled.ButtonText>Other Language</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="NoAnswer"
        onClick={() => handleClick(DispositionNames.NoAnswer)}
      >
        <Styled.SVGIcon src={NO_ANSWER} />
        <Styled.ButtonText>No Answer</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption id="Silent" onClick={() => handleClickSilent()}>
        <Styled.SVGIcon src={SILENT} />
        <Styled.ButtonText>Silent</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="SitTone"
        onClick={() => handleClick(DispositionNames.SpecialTone)}
      >
        <Styled.SVGIcon src={HEADPHONES} />
        <Styled.ButtonText>Special Tone</Styled.ButtonText>
      </Styled.DispositionOption>
    </>
  );
};

export default DefaultDisposition;
