import {
  HandoffScreenContainer,
  HandoffScreenMiddleWidget,
  HandoffScreenDottedBox,
  HandoffScreenArrowIcon,
  HandoffScreenTitle,
  HandoffScreenText,
} from 'components/Handoffs/HandoffScreen.styled';
import ArrowNarrowRight from 'assets/icons/arrow-narrow-right.svg';

export const HandoffScreen = () => (
  <HandoffScreenContainer>
    <HandoffScreenMiddleWidget>
      <HandoffScreenDottedBox>
        <div>
          <HandoffScreenArrowIcon src={ArrowNarrowRight} />
        </div>
      </HandoffScreenDottedBox>
      <HandoffScreenTitle>Handoff has been Completed.</HandoffScreenTitle>
      <HandoffScreenText>Exiting...</HandoffScreenText>
    </HandoffScreenMiddleWidget>
  </HandoffScreenContainer>
);
