import * as Styled from './Dispositions.styled';
import OTHLANG from 'assets/icons/language-icon.svg';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import { useEditorEvents } from 'shared/components/ui/editor/editor/useEditorEvents';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { useState } from 'react';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';

const OTHLANG_QUICKWORD_CODE = 'olangcall';

interface IOthLangDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
  sendTerminateCall: (reason: ReasonCodes) => void;
}

const OthLangDisposition = ({
  setSelectedDisposition,
  cancelDisposition,
  sendTerminateCall,
}: IOthLangDisposition) => {
  const { appendCaption } = useEditorEvents();
  const [isSecondTimer, setIsSecondTimer] = useState(false);
  const quickwords = useSelector(
    (state: RootState) => state.quickwords.quickwords
  );

  const timerExpired = () => {
    if (isSecondTimer) {
      sendTerminateCall(ReasonCodes.OthLang);
    } else {
      setIsSecondTimer(true);
      appendCaption(quickwords[OTHLANG_QUICKWORD_CODE] ?? '');
    }
  };

  const canceledTimer = () => {
    cancelDisposition();
    setSelectedDisposition(DispositionNames.Normal);
  };

  return (
    <Styled.DispositionContainer>
      <Styled.DispositionHeaderContainer>
        <Styled.SVGDispositionIcon src={OTHLANG} />
        <Styled.DispositionIconLabel>
          Other Language
        </Styled.DispositionIconLabel>
      </Styled.DispositionHeaderContainer>
      {isSecondTimer ? (
        <>
          <Styled.DispositionsLabelContainer>
            <Styled.TextOnly>Call Ending in</Styled.TextOnly>
          </Styled.DispositionsLabelContainer>
          <Timer
            key="second-timer"
            seconds={240}
            timerExpired={timerExpired}
            canceledTimer={canceledTimer}
            footerText="Cancel if caller begins speaking in your captioning language"
          />
        </>
      ) : (
        <>
          <Styled.DispositionsLabelContainer>
            <Styled.SubTextOnly>
              Listen to verify the call is in a language other than English or
              Spanish
            </Styled.SubTextOnly>
          </Styled.DispositionsLabelContainer>
          <Timer
            key="first-timer"
            seconds={30}
            timerExpired={timerExpired}
            canceledTimer={canceledTimer}
          />
        </>
      )}
    </Styled.DispositionContainer>
  );
};

export default OthLangDisposition;
