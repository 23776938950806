const CONSTANTS = {
  TABLE_Y_SCROLL_SUBTRACK: 320,
  CSV_TYPE: 'text/csv',
  OPERATIVE_SYSTEMS: {
    WINDOWS: 'win',
    MAC: 'mac',
  },
  BROWSERS: {
    SAFARI: 'safari',
    FIREFOX: 'firefox',
    CHROME: 'chrome',
  },
  ALTERNATIVES_CLASS: 'alternative-selection',
  WORD_LIMIT: 200,
} as const;

export default CONSTANTS;
