import { FC, useMemo } from 'react';
import * as Styled from './Handoffs.styled';
import { HANDOFFS } from 'modules/ipcts-call-session/components/controls/context/call-handoff.types';

const REQUIRED_SECONDS = 600;

interface IProps {
  callDuration: number;
  setHandoffType: React.Dispatch<React.SetStateAction<string | null>>;
}

const DefaultHandoffs: FC<IProps> = ({ callDuration, setHandoffType }) => {
  const isBelowRequiredSeconds = useMemo(() => {
    if (callDuration < REQUIRED_SECONDS) {
      return true;
    }
    return false;
  }, [callDuration]);

  const handoffClickHandler = (type: string) => {
    setHandoffType(type);
    localStorage.setItem('callLength', `${callDuration}`);
  };

  return (
    <Styled.HandoffButtonsContainer>
      <Styled.HandoffOptionButton id="spanishSpeakerButton" onClick={() => {}}>
        <Styled.ButtonText>{HANDOFFS.ENGLISH_SPANISH.LABEL}</Styled.ButtonText>
      </Styled.HandoffOptionButton>
      <Styled.HandoffOptionButton
        id="emergencyButton"
        onClick={() => handoffClickHandler(HANDOFFS.EMERGENCY.LABEL)}
      >
        <Styled.ButtonText>{HANDOFFS.EMERGENCY.LABEL}</Styled.ButtonText>
      </Styled.HandoffOptionButton>
      <Styled.HandoffOptionButton
        id="breakButton"
        onClick={() => handoffClickHandler('Break')}
        disabled={isBelowRequiredSeconds}
      >
        <Styled.ButtonText isDisabled={isBelowRequiredSeconds}>
          {HANDOFFS.BREAK_LUNCH.LABEL}
        </Styled.ButtonText>
      </Styled.HandoffOptionButton>
      <Styled.HandoffOptionButton
        id="meetingButton"
        onClick={() => handoffClickHandler(HANDOFFS.MEETING.LABEL)}
        disabled={isBelowRequiredSeconds}
      >
        <Styled.ButtonText isDisabled={isBelowRequiredSeconds}>
          {HANDOFFS.MEETING.LABEL}
        </Styled.ButtonText>
      </Styled.HandoffOptionButton>
      <Styled.HandoffOptionButton
        id="trainingButton"
        onClick={() => handoffClickHandler(HANDOFFS.TRAINING.LABEL)}
        disabled={isBelowRequiredSeconds}
      >
        <Styled.ButtonText isDisabled={isBelowRequiredSeconds}>
          {HANDOFFS.TRAINING.LABEL}
        </Styled.ButtonText>
      </Styled.HandoffOptionButton>
      <Styled.HandoffOptionButton
        id="endShiftButton"
        onClick={() => handoffClickHandler('EndOfShift')}
        disabled={isBelowRequiredSeconds}
      >
        <Styled.ButtonText isDisabled={isBelowRequiredSeconds}>
          {HANDOFFS.END_OF_SHIFT.LABEL}
        </Styled.ButtonText>
      </Styled.HandoffOptionButton>
    </Styled.HandoffButtonsContainer>
  );
};

export default DefaultHandoffs;
