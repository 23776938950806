import { DateTime } from 'luxon';
import { config } from 'shared/config/cts.config';
import logger from 'services/logger';
import packageJson from '../../../../package.json';

export enum BillingSessionStates {
  InCall = 'InCall',
  CallDelay = 'CallDelay',
  JoinQueue = 'JoinQueue',
  WaitingForNextCall = 'WaitingForNextCall',
  LeaveQueue = 'LeaveQueue',
}
const appVersion = packageJson.version;
class EventMessagePayload {
  private Metadata: Record<string, string>;

  constructor(payloadBody: Record<string, any>) {
    this.Metadata = {
      AWS_REGION: config.REACT_APP_DEPLOY_REGION || '',
      AWS_ACCOUNT_ID: config.REACT_APP_AWS_ACCOUNT || '',
    };
    Object.assign(this, payloadBody);
  }
}
type EventMessageData = {
  event: string;
  payload: EventMessagePayload;
};
class DataScienceEventMessage {
  private PartitionKey: string;

  private Data: EventMessageData;

  constructor(eventName: string, payload: EventMessagePayload) {
    this.PartitionKey = '6322971a-b413-4acf-bd6e-83f8b21c724e';
    this.Data = {
      event: eventName,
      payload,
    };
  }
}

class SessionLoginEventMessage extends DataScienceEventMessage {
  constructor(
    AgentSessionID: string,
    StartDt: string,
    LanguageCode: string,
    AgentID: string,
    HomeCallCenterID: string,
    HomeCallCenterName: string,
    SessionCallCenterID: string,
    SessionCallCenterName: string,
    Email: string,
    DesktopVersion: string
  ) {
    super(
      'agent_session_login',
      new EventMessagePayload({
        AgentSession: {
          AgentSessionID,
          StartDt,
          LanguageCode,
          AgentID,
          ApplicationCode: 'Captioner',
          ApplicationSoftwareVersion: appVersion,
          HomeCallCenterID,
          HomeCallCenterName,
          MachineID: '',
          SessionCallCenterID,
          SessionCallCenterName,
          State: 'login',
          StationNumber: -1,
          Email,
          DesktopVersion,
        },
      })
    );
  }
}
class SessionLogoutEventMessage extends DataScienceEventMessage {
  constructor(
    AgentSessionID: string,
    AgentID: string,
    EndDt: string,
    ModifiedDt: string,
    Email: string,
    DesktopVersion: string
  ) {
    super(
      'agent_session_logout',
      new EventMessagePayload({
        AgentSession: {
          AgentSessionID,
          AgentID,
          EndDt,
          ModifiedDt,
          ApplicationCode: 'Captioner',
          ApplicationSoftwareVersion: appVersion,
          State: 'logout',
          Email,
          DesktopVersion,
        },
      })
    );
  }
}
class SessionUpdateEventMessage extends DataScienceEventMessage {
  constructor(
    AgentSessionID: string,
    AgentID: string,
    ModifiedDt: string,
    State: BillingSessionStates,
    Email: string,
    DesktopVersion: string
  ) {
    super(
      'agent_session_update',
      new EventMessagePayload({
        AgentSession: {
          AgentSessionID,
          AgentID,
          ModifiedDt,
          ApplicationCode: 'Captioner',
          ApplicationSoftwareVersion: appVersion,
          State,
          Email,
          DesktopVersion,
        },
      })
    );
  }
}
export const useSendDataScienceEventMessage = () => {
  async function putEventMessage(
    userToken: string,
    message: DataScienceEventMessage
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);
    try {
      await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/datascience-message`,
        {
          method: 'PUT',
          headers: requestHeaders,
          mode: 'cors',
          body: JSON.stringify(message),
        }
      );
    } catch (error: any) {
      logger.error({
        methodName: 'putEventMessage',
        message: `Failed with error message ${error.message}`,
        errorStack: error.stack,
      });
    }
  }

  const sendDataScienceEventMessage = async (
    userToken: string,
    message: DataScienceEventMessage
  ) => {
    await putEventMessage(userToken, message);
  };
  const sendSessionLoginEventMessage = async (
    userToken: string,
    AgentSessionID: string,
    StartDt: string,
    LanguageCode: string,
    AgentID: string,
    HomeCallCenterID: string,
    HomeCallCenterName: string,
    SessionCallCenterID: string,
    SessionCallCenterName: string,
    Email: string,
    DesktopVersion: string
  ) => {
    const message: SessionLoginEventMessage = new SessionLoginEventMessage(
      AgentSessionID,
      StartDt,
      LanguageCode,
      AgentID,
      HomeCallCenterID,
      HomeCallCenterName,
      SessionCallCenterID,
      SessionCallCenterName,
      Email,
      DesktopVersion
    );
    await putEventMessage(userToken, message);
  };
  const sendSessionLogoutEventMessage = async (
    userToken: string,
    AgentSessionID: string,
    AgentID: string,
    EndDt: string,
    ModifiedDt: string,
    Email: string,
    DesktopVersion: string
  ) => {
    const message: SessionLogoutEventMessage = new SessionLogoutEventMessage(
      AgentSessionID,
      AgentID,
      EndDt,
      ModifiedDt,
      Email,
      DesktopVersion
    );
    await putEventMessage(userToken, message);
  };
  const sendSessionUpdateEventMessage = async (
    userToken: string,
    AgentSessionID: string,
    AgentID: string,
    State: BillingSessionStates,
    Email: string,
    DesktopVersion: string
  ) => {
    const message: SessionUpdateEventMessage = new SessionUpdateEventMessage(
      AgentSessionID,
      AgentID,
      `${DateTime.now().toISO()}`,
      State,
      Email,
      DesktopVersion
    );
    await putEventMessage(userToken, message);
  };
  return {
    sendDataScienceEventMessage,
    sendSessionLoginEventMessage,
    sendSessionLogoutEventMessage,
    sendSessionUpdateEventMessage,
  };
};
