import React, { useEffect } from 'react';
import * as Styled from './Dispositions.styled';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { useEditorEvents } from 'shared/components/ui/editor/editor/useEditorEvents';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';
import ILLEGITIMATE from 'assets/icons/illegitimate-icon.svg';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

interface IIllegitDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
  sendTerminateCall: (reason: ReasonCodes) => void;
}
const ILLEGIT_CODES = ['illsupcall1', 'illsupcall2', 'illsupcall3'];

const IllegitDisposition = ({
  setSelectedDisposition,
  cancelDisposition,
  sendTerminateCall,
}: IIllegitDisposition) => {
  const { appendCaption } = useEditorEvents();
  const quickwords = useSelector(
    (state: RootState) => state.quickwords.quickwords
  );

  useEffect(() => {
    const captionText = ILLEGIT_CODES.map(
      (code) => `${quickwords[code] ?? ''}`
    ).join('<br/>');
    appendCaption(captionText);
  }, []);

  const timerExpired = () => {
    sendTerminateCall(ReasonCodes.Silent);
  };

  const canceledTimer = () => {
    cancelDisposition();
    setSelectedDisposition(DispositionNames.Normal);
  };

  return (
    <Styled.DispositionContainer>
      <Styled.DispositionHeaderContainer>
        <Styled.SVGDispositionIcon src={ILLEGITIMATE} />
        <Styled.DispositionIconLabel>Illegitimate</Styled.DispositionIconLabel>
      </Styled.DispositionHeaderContainer>
      <Styled.DispositionsLabelsContainer>
        <Styled.TextOnly>Call Ending in</Styled.TextOnly>
      </Styled.DispositionsLabelsContainer>
      <Timer
        key="illegit-timer"
        seconds={10}
        timerExpired={timerExpired}
        canceledTimer={canceledTimer}
        footerText="Cancel if audio resumes"
      />
    </Styled.DispositionContainer>
  );
};

export default IllegitDisposition;
