import { Button } from 'antd';
import styled from 'styled-components';

type ButtonTextProps = {
  isDisabled?: boolean;
};

export const HandoffButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`;

export const HandoffOptionButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.handoffButton};
  border: 0px;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.handoffButton}!important;
  }
  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabled};
    color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabledText}!important;
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabled};
      color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabledText}!important;
    }
  }
`;

export const ButtonText = styled.span<ButtonTextProps>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.colors.handoffRequestButtonDisabledText
      : theme.colors.handoffRequestButtonText};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  float: left;
`;
