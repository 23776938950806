import { useContext, useRef, useState } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';
import { AffectedShards } from 'shared/hooks/axon/corrections/corrections.types';
import { RootState } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCaption,
} from 'state/captions/captionsSlice';
import {CaptionShard, CaptionShardWord} from 'shared/hooks/axon/gateway.types';
import logger from 'services/logger';

const LINE_BREAK = '<br/>';
const PUNCTUATION = '.,:;?!'
const MAX_WORD_SELECTION = 3;
interface CustomSelection extends Partial<Selection> {
  anchorOffset: number;
  focusOffset: number;
  focusNode: Node;
  removeAllRanges: () => {};
  addRange: (range: any) => {};
}
export const useEditorEvents = () => {
  const [focusedShard, setFocusedShard] = useState<any | null>(null);
  const [multiWordEdit, setMultiWordEdit] = useState<any | null>(null)
  const dispatch = useDispatch();
  const captions = useSelector((state: RootState) => state.captions.value);
  const functionKeys = useSelector((state: RootState) => state.quickwords.functionKeys);
  const quickwords = useSelector((state: RootState) => state.quickwords.quickwords);
  const editorAnchorOffset = useRef(-1);

  const {
    sendShardCorrection,
  } = useContext(IPCTSSessionContext);

  function moveCaretOutOfEditor(){
    const elementToFocus = document?.getElementById('rsw-cts-editor')?.parentElement?.parentElement;
    setNodeCaretPosition(elementToFocus, 0)
    document?.getElementById('rsw-cts-editor')?.blur();
    setNewShardFocus(null);
  }

  function formatMultiWordCorrection() {
    let editor = document.getElementById('rsw-cts-editor');
    let foundPrevious = false;
    for (let shard of multiWordEdit?.affectedShards) {
      let shardParagraph = editor?.querySelector(`p[data-shardid="${shard.shard_id}"]`)
      if (shardParagraph) {
        foundPrevious = true;
        if (shard.old_text != shardParagraph.textContent) {
          let newValue = removeZeroWidthSpace(shardParagraph.textContent as string);
          if (newValue.trim() === '') {
            newValue = '';
          }
          shard.new_text = newValue;
        } else {
          shard.new_text = '';
        }
      } else if (foundPrevious) {
        shard.new_text = '';
      }
    }
    return multiWordEdit?.affectedShards;
  }
  function formatAndSendCorrection(affectedShards: AffectedShards[]) {
    logger.info({
      methodName: 'formatAndSendCorrection',
      parameters: { affectedShards },
    }, false);
    sendShardCorrection(affectedShards)
    setFocusedShard(null)
    setMultiWordEdit(null);
  }

  const removeZeroWidthSpace = function (text: string) {
    return text.replaceAll(zeroWidthJoiner.space, '');
  }
  function setNodeCaretPosition(elementNode: any, position: number) {
    try{
      if(!elementNode){
        logger.warn({
          methodName: 'setNodeCaretPosition',
          message: `elementNode is null or undefined`,
          parameters: {
            elementNode,
            position,
          }
        }, false);
      }
      const selectedRange = document.createRange();
      selectedRange.setStart(elementNode, position);
      selectedRange.collapse(true);
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(selectedRange);
    } catch(error: any){
      let shardId = elementNode?.attributes?.['data-shardid']?.value || elementNode?.parentElement?.attributes?.['data-shardid']?.value;
      logger.error({
        methodName: 'setNodeCaretPosition',
        message: `For shard ${shardId} got error ${error.message}`,
        errorStack: error.stack,
        parameters: {
          elementNode: elementNode.toString(),
          position,
        }
      });
    }
  }

  function setCaretPosition(element: any, childNodeIndex: number, position: number) {
    const selectedRange = document.createRange();
    selectedRange.setStart(element.childNodes[childNodeIndex], position);
    selectedRange.collapse(true);
    const selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(selectedRange);
  }
  function setNewShardFocus(newElement: any) {
    if (newElement === focusedShard) {
      return;
    }
    try {
      let newShardId = newElement?.attributes?.['data-shardid']?.value;
      if(!newShardId){
        newShardId = newElement?.parentElement?.attributes?.['data-shardid']?.value;
        if(!newShardId){
          logger.warn({
            methodName: '** setNewShardFocus',
            message: `could not set shard focus to element ${newElement?.toString()}`,
          }, false);
          return;
        }
      }
      let focusedShardId = focusedShard?.attributes?.['data-shardid']?.value;
      logger.info({
        methodName: '** setNewShardFocus',
        message: `to ${newShardId} from ${focusedShardId}`,
      }, false);
      setFocusedShard(newElement)
    } catch (error: any) {
      logger.error({
        methodName: '** setNewShardFocus',
        message: `failed for newElement: ${newElement} focusedShard: ${focusedShard} with error ${error.message}`,
        errorStack: error.stack,
      }, false);
    }
  }

  const handleFocus = (event: any) => { }
  const handleBlur = () => {
    setNewShardFocus(null)
  }
  function moveCaretToPreviousWord(textNode: any){
    const shardWord = textNode?.parentElement;
    if(textNode === shardWord?.firstChild ){
      const shard = shardWord?.parentElement;
      if(shardWord === shard?.firstChild ){
        // move to end of the last text node of the last shard word of the previous shard
        const prevoiusShard = shard?.previousElementSibling;
        const previousWord = prevoiusShard?.lastElementChild;
        const previoudShardWordText = previousWord?.lastChild;
        setNodeCaretPosition(previoudShardWordText, previoudShardWordText?.textContent?.length)
      } else if(shard?.childNodes?.length > 1){
        // move to end of the last text node of the previous shard word of the current shard
        const currentWordIndex = [...shard?.childNodes].findIndex((wordNode :any) => (wordNode === shardWord));
        const previoudShardWordText = shard?.childNodes?.[currentWordIndex-1]?.lastChild;
        setNodeCaretPosition(previoudShardWordText, previoudShardWordText?.textContent?.length)
      }
    } else if(shardWord?.childNodes?.length > 1){
      // move to the end of the previous text node of the current shard word
      const currentNodeIndex = [...shardWord?.childNodes].findIndex((node:any) => (node === textNode));
      const previoudShardWordText = shardWord?.childNodes?.[currentNodeIndex-1]?.lastChild;
      setNodeCaretPosition(previoudShardWordText, previoudShardWordText?.textContent?.length)
    }
  }
  function handleArrowLeft(element: any, fromElement: any = focusedShard) {
    let selectionElement = fromElement?.previousElementSibling;
    if (element === selectionElement
      || ((element === focusedShard?.parentNode
        || element.attributes?.['data-type']?.value === 'shard-word' && element.parentNode !== focusedShard)
        && selectionElement?.attributes?.['data-type']?.value === 'shard')) {
      let lastChildNodeIndex = selectionElement.childNodes.length - 1;
      if (selectionElement.childNodes[lastChildNodeIndex].nodeType !== Node.TEXT_NODE) {
        selectionElement = selectionElement.childNodes[lastChildNodeIndex];
        lastChildNodeIndex = selectionElement.childNodes.length - 1;
      }
      const position = selectionElement.childNodes[lastChildNodeIndex].length;
      setCaretPosition(selectionElement, lastChildNodeIndex, position);
      setNewShardFocus(focusedShard?.previousElementSibling);
    }
  }
  function handleArrowRight(element: any) {
    let selectionElement = focusedShard?.nextElementSibling;
    if ((element === focusedShard?.parentNode
      || element === selectionElement)
      && selectionElement?.attributes?.['data-type']?.value === 'shard') {
      let firstChildNodeIndex = 0;
      if (selectionElement.childNodes[firstChildNodeIndex].nodeType !== 3) {
        selectionElement = selectionElement.childNodes[firstChildNodeIndex];
        firstChildNodeIndex = 0;
      }
      const position = 1;
      setCaretPosition(selectionElement, firstChildNodeIndex, position)
      setNewShardFocus(focusedShard?.nextElementSibling)
    }
  }
  function handleArrowUpDown(element: any) {
    if (element === focusedShard) {
      return;
    }
    const elementDataType = element.attributes?.['data-type']?.value;
    let newFocusedShard = null;
    if (elementDataType === 'shard') {
      newFocusedShard = element;
    } else if (elementDataType === 'shard-word') {
      newFocusedShard = element.parentNode;
    } else if (element === focusedShard?.parentNode) {
      newFocusedShard = element?.nextElementSibling ? element?.nextElementSibling : element?.previousElementSibling;
    }
    setNewShardFocus(newFocusedShard)
  }
  const elementIsBeyondEditableLimit = (element: any) => {
    return element.className?.includes('beyond-editable-limit')
        || element.parentElement?.className?.includes('beyond-editable-limit');
  }
  const handleKeyDown = (event: any) => {
    const selection = (window as any)?.getSelection();
    let selectionAnchor = selection?.anchorNode;
    let anchorOffset = selection?.anchorOffset;
    if(selectionAnchor.nodeType !== Node.TEXT_NODE){
      if(selectionAnchor?.attributes?.['data-type']?.value === 'shard'
      && selection.anchorOffset === 0){
        const prevoiusShard = selectionAnchor?.previousElementSibling;
        if(prevoiusShard){
          const previousWord = prevoiusShard?.lastElementChild;
          const previoudShardWordText = previousWord?.lastChild;
          setNodeCaretPosition(previoudShardWordText, previoudShardWordText?.textContent?.length)
          selectionAnchor = previoudShardWordText;
          anchorOffset = previoudShardWordText?.textContent?.length;
        } else {
          const firstWord = selectionAnchor?.firstElementChild;
          const firstTextNodeOfFirstWord = selectionAnchor?.firstElementChild?.firstChild;
          if(firstTextNodeOfFirstWord?.textContent?.length > 0){
            setNodeCaretPosition(firstTextNodeOfFirstWord, 1)
            anchorOffset = 1;
            selectionAnchor = firstTextNodeOfFirstWord;
          }
        }
      }
    }

    const shardWordElement = selectionAnchor?.parentNode;
    const shardElement = shardWordElement?.parentNode;
    if(elementIsBeyondEditableLimit(shardWordElement)){
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    let shardId = getSavvyId(shardElement)
    let wordId = getSavvyId(shardWordElement)
    const originalValue = shardElement?.attributes?.['data-text']?.value;
    if(anchorOffset === 0 && editorAnchorOffset.current !== -1){
      anchorOffset = editorAnchorOffset.current;
    }

    switch (event.key) {
      // 'Home' key
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ContextMenu':
      case 'Shift':
        // allow event, don't preventDefault or stopPropagation;
        return;
      case 'Enter':
      case 'Tab':
      case 'Clear':
      case 'Delete':
      case 'EraseEof':
      case 'ExSel':
      case 'Insert':
      case 'Paste':
      case 'Redo':
      case 'Undo':
      case 'Again':
        // don't do anything just preventDefault and stopPropagation
        break;
      case 'Backspace':
        if(shardWordElement?.className?.includes('ant-popover-open')){
          break;
        }

        if(selection.type === 'Range'){
          let highlightedShards = getHighlightedShards(selection);
          if (highlightedShards?.selectedShardWords?.length) {
            if (highlightedShards.selectedShardWords.length > MAX_WORD_SELECTION) {
              logger.warn({
                methodName: '** handleKeyDown',
                message: `Backspace key reduce selection`,
              }, false);
              reduceSelectionRange(highlightedShards)
            } else if (!multiWordEdit?.affectedShards?.length) {
              setAffectedShards(event, highlightedShards)
            }
          }
        }
        else if(selectionAnchor.nodeType === Node.TEXT_NODE
        && selectionAnchor.parentNode.attributes?.['data-type']?.value === 'shard-word'){
          // get word id
          // translate to position in shardWord wordText
          // remove the char at postion from wordText
          // recreate text for shard
          let captionShard = JSON.parse(JSON.stringify(captions[shardId]));
          let shardWord = captionShard.shardWords.find((word: CaptionShardWord) => (word.wordId === wordId));
          const offset = anchorOffset - 1;
          let charToDelete = shardWordElement.textContent[offset]
          if(charToDelete !== zeroWidthJoiner.space){
            if(offset > 0 && offset < shardWord.wordText.length ){
              shardWord.wordText = shardWord.wordText.slice(0, offset) + shardWord.wordText.slice(anchorOffset);
              dispatch(setCaption(captionShard))
              if(removeZeroWidthSpace(shardWord.wordText)){
                setTimeout(() => {
                  setNodeCaretPosition(selectionAnchor, offset)
                });
              } else {
                setTimeout(() => {
                  moveCaretToPreviousWord(selectionAnchor)
                });
                const originalValue = shardElement.attributes?.['data-text']?.value;
                let newShardText = captionShard.shardWords
                  .filter((word:CaptionShardWord) => (word.wordId != wordId))
                  .map((word:CaptionShardWord) => (word.wordText))
                  .join('');
    
                formatAndSendCorrection( [{
                  shard_id: shardId,
                  old_text: originalValue,
                  new_text: removeZeroWidthSpace(newShardText),
                }]);
              }
            }            
          } else {
            logger.info({
              methodName: 'handleKeyDown',
              message: '** Backspace do not delete zeroWidthJoiner space',
            }, false);
            setTimeout(() => {
              moveCaretToPreviousWord(selectionAnchor)
            });
          }
        }
        break;
      case 'F1':
      case 'F2':
      case 'F3':
      case 'F4':
      case 'F5':
      case 'F6':
      case 'F7':
      case 'F8':
      case 'F9':
      case 'F10':
      case 'F11':
      case 'F12':
        // Functon Key
        if(wordId !== undefined && !isNaN(wordId)){
          let functionKeySubstitution = functionKeys[event.key]
          if(functionKeySubstitution){
            const cursorIndex = anchorOffset;
            let insertBefore = false;
            let shardWordLength = shardWordElement.textContent.length;
            let compensateAmount = 0;
            let shard = captions[shardId];
            let shardWord = shard.shardWords.find(word => {
              return word.wordId == wordId
            });
            let replacementText = '';

            // are we inserting before punctuation
            let endOfWord = shardWordElement.textContent.substring(cursorIndex).trim();
            if(PUNCTUATION.includes(endOfWord.charAt(0)) && shardWord?.wordText?.length){
              const indexFromEnd = shardWordElement.textContent.length - cursorIndex;
              const insertIndex = shardWord?.wordText?.length - indexFromEnd;
              const shardWordStart = shardWord?.wordText.substring(0, insertIndex)
              const shardWordEnd = shardWord?.wordText.substring(insertIndex)
              replacementText = shardWordStart
                              + ' '
                              + functionKeySubstitution
                              + shardWordEnd;
            } else {
              // are we closer to the beginning or end of the word
              // insert at which ever is closest
              if(shardWordLength === 0){
                insertBefore = true;
              } else if(shardWordElement.textContent.charAt(0) === zeroWidthJoiner.space
                    || shardWordElement.textContent.charAt(0) === ' '){
                ++compensateAmount;
                if(shardWordLength > 0
                && shardWordElement.textContent.charAt(1) === ' '){
                  ++compensateAmount;
                }
                let wordMiddle = (shardWordLength-compensateAmount)/2;
                if((cursorIndex - compensateAmount) <= wordMiddle){
                  // insert at beginning
                  insertBefore = true;
                }
              }

              replacementText = insertBefore
                ? ' ' + functionKeySubstitution + shardWord?.wordText
                : shardWord?.wordText + ' ' + functionKeySubstitution;
            }
            const affectedShards: AffectedShards[] = [];
            let newShardText = replaceWordInShardText(shard, wordId, replacementText)
            affectedShards.push({
              shard_id: shardId,
              old_text: originalValue,
              new_text: newShardText,
            });
            formatAndSendCorrection(affectedShards);
            moveCaretOutOfEditor();
          }
        } else if(Object.keys(captions).length === 0
               && event.key.length > 1
               && event.key.charAt(0) === 'F'
               && !isNaN(event.key.charAt(1))){
          // check for function key used before any captions received
          let functionKeySubstitution = functionKeys[event.key]
          const affectedShards = [{
            shard_id: -1,
            old_text: '',
            new_text: functionKeySubstitution,
          }];

          formatAndSendCorrection(affectedShards);
        }
        break;
      default:
        let keyChar = event.key;
        if(keyChar === 'Decimal'){
          keyChar = '.';
        }
        if([...keyChar].length === 1 // convert to array to properly handle multibyte chars
        && !shardWordElement?.className?.includes('ant-popover-open')
        && selectionAnchor.nodeType === Node.TEXT_NODE
        && selectionAnchor.parentNode.attributes?.['data-type']?.value === 'shard-word'){
          // don't allow paste key commands
          if(event.metaKey){
            break;
          }
          
          if (selection?.type === 'Range' && selection.baseNode !== selection.extentNode) {
            let highlightedShards = getHighlightedShards(selection);
            if (highlightedShards?.selectedShardWords?.length) {
              if (highlightedShards.selectedShardWords.length > MAX_WORD_SELECTION) {
                logger.warn({
                  methodName: '** handleKeyDown',
                  message: `Enter key reduce selection`,
                }, false);
                reduceSelectionRange(highlightedShards)
              } else if (!multiWordEdit?.affectedShards?.length) {
                setAffectedShards(event, highlightedShards);
              }
            }
          } else if(anchorOffset > 0) {
            let captionShard = JSON.parse(JSON.stringify(captions[shardId]));
            let shardWord = captionShard.shardWords.find((word: CaptionShardWord) => (word.wordId === wordId));
            if(selection?.type === 'Range') {
              shardWord.wordText = shardWord.wordText.slice(0, anchorOffset) + keyChar;
            } else {
              shardWord.wordText = shardWord.wordText.slice(0, anchorOffset) + keyChar + shardWord.wordText.slice(anchorOffset);
            }

            captionShard.shardText = captionShard.shardWords
              .map((word:CaptionShardWord) => (removeZeroWidthSpace(word.wordText)))
              .join('');

            dispatch(setCaption(captionShard));
            editorAnchorOffset.current = anchorOffset+1;
            setTimeout(() => {
              setNodeCaretPosition(selectionAnchor, anchorOffset+1)
            });
          }
        }
        break;
    }
    event.preventDefault();
    event.stopPropagation();
  };

  function setAffectedShards(event: any, highlightedShards: any) {
    let affectedShards: AffectedShards[] = [];
    const lastHighlightedShardWordIndex = highlightedShards.selectedShardWords.length - 1;
    const inputCharacter = event.key === 'Backspace' ? '' : event.key;
    const caretPositionLeftOffset = event.key === 'Backspace' ? 0 : 1;
    let startWordId = getSavvyId(highlightedShards.selectedShardWords[0]);
    let endWordId = getSavvyId(highlightedShards.selectedShardWords[lastHighlightedShardWordIndex]);
    let endShardId = highlightedShards.selectedShardWords[lastHighlightedShardWordIndex]?.attributes?.['data-shardid']?.value;
    if(endShardId?.includes('.')){
      endShardId = parseFloat(endShardId);
    } else {
      endShardId = parseInt(endShardId, 10);
    }
    let foundWord = false;
    let startWord = null
    let foundEndWord = false;
    let firstShardId = 0;
    let tempShards = [];
    for (let index = 0; index < highlightedShards.selectedShards.length; ++index) {
      let shard = highlightedShards.selectedShards[index];
      const shardId = getSavvyId(shard);
      if (index === 0) {
        firstShardId = shardId;
      }
      let captionShard = JSON.parse(JSON.stringify(captions[shardId]));
      tempShards.push(captionShard);
      captionShard.userEditing = true;

      for (let shardWordIndex = 0; shardWordIndex < captionShard.shardWords.length; ++shardWordIndex) {
        let shardWord = captionShard.shardWords[shardWordIndex]
        let endText = '';
        if (foundEndWord) {
          break;
        }
        if (shardWord.wordId === startWordId) {
          startWord = shardWord;
          foundWord = true;
          if (shardWord.wordId === endWordId
            && shardId === endShardId) {
            endText = shardWord.wordText.substring(highlightedShards.endWordOffset);
            foundEndWord = true;
          }
          shardWord.wordText = shardWord.wordText.substring(0, highlightedShards.startWordOffset) + inputCharacter + endText;
          continue;
        }
        if (foundWord) {
          if (shardWord.wordId === endWordId
            && shardId === endShardId) {
            endText = shardWord.wordText.substring(highlightedShards.endWordOffset);
            foundEndWord = true;
            let firstShard = tempShards[0];
            // append to last word of first shard
            if (firstShardId === endShardId && startWord) {
              startWord.wordText += endText
            } else {
              let lastWord = firstShard.shardWords.length - 1;
              firstShard.shardWords[lastWord].wordText += endText;
            }
          }
          shardWord.wordText = '';
        }
      }
      const affectedShard: AffectedShards = {
        shard_id: shardId,
        old_text: shard.attributes?.['data-text']?.value,
        new_text: '',
      }
      affectedShards.push(affectedShard)
    }
    for (let tempShard of tempShards) {
      dispatch(setCaption(tempShard))
    }
    setTimeout(() => {
      setNodeCaretPosition(highlightedShards.leftElement, highlightedShards.leftOffset + caretPositionLeftOffset)
    })
    logger.info({
      methodName: '** setAffectedShards',
      message: JSON.stringify(affectedShards),
    }, false);
    setMultiWordEdit({
      isLeftToRight: highlightedShards.isLeftToRight,
      affectedShards,
    });
  }
  function getSavvyId(element: any){
    let savvyType = element.attributes?.['data-type']?.value === 'shard' ? 'shard' : 'word';
    let id = element?.attributes?.[`data-${savvyType}id`]?.value;
    if(id?.includes('.')){
      return parseFloat(id);
    } else {
      return parseInt(id, 10)
    }
  }
  const handleKeyup = (event: any) => {
    const selection = (window as any)?.getSelection();
    const selectionAnchor = selection?.anchorNode;
    const anchorOffset = selection.anchorOffset;
    let element = selectionAnchor?.parentNode;
    const shardWordElement = selectionAnchor?.parentNode;
    const shardElement = shardWordElement?.parentNode;
    if(elementIsBeyondEditableLimit(shardWordElement)){
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    let shardId = getSavvyId(shardElement);
    const originalValue = shardElement?.attributes?.['data-text']?.value

    switch (event.key) {
      case 'Backspace':
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        if (element.id === "rsw-cts-editor") {
          element = selectionAnchor.previousElementSibling || selectionAnchor.nextElementSibling;
        }
        handleArrowUpDown(element)
        return;
      case 'ArrowLeft':
        if (element.id === "rsw-cts-editor") {
          if (!selectionAnchor.previousElementSibling) {
            return;
          }
          element = selectionAnchor.previousElementSibling
        }
        return;
      case 'ArrowRight':
        if (!selectionAnchor.nextElementSibling) {
          return;
        }
        element = selectionAnchor.nextElementSibling
        handleArrowRight(element);
        return;
      case 'Enter':
        if(selectionAnchor.nodeType === Node.TEXT_NODE
        && selectionAnchor.parentNode.attributes?.['data-type']?.value === 'shard-word'){

          editorAnchorOffset.current = -1;
          if (event.ctrlKey) {
            const cursorIndex = anchorOffset;
            const text = shardWordElement.textContent;
            const shardStart = text.substring(0, cursorIndex)
            const shardEnd = text.substring(cursorIndex)
            const newText = shardStart + LINE_BREAK + shardEnd;
            const affectedShards: AffectedShards[] = [{
              shard_id: shardId,
              old_text: originalValue,
              new_text: removeZeroWidthSpace(newText),
            }];
            formatAndSendCorrection(affectedShards);
            logger.info({
              methodName: '** handleKeyup',
              message: 'send linebreak ShardCorrection ' + shardWordElement.toString(),
            }, false);
            moveCaretOutOfEditor();
            break;
          }

          logger.info({
            methodName: '** handleKeyup',
            message: `sendShardCorrection ${shardId} ${shardWordElement.textContent}`,
          }, false);

          const shardWords = removeZeroWidthSpace(shardElement.textContent).trim().split(' ');// element is the shardword span not the shard
          let foundQuickWord = false;
          for(let shardWordIndex = 0; shardWordIndex < shardWords.length; ++shardWordIndex){
            // trim any beginnnig or ending punctuation.
            let shardWord = shardWords[shardWordIndex].replace(/[·.,\/#!$%\^&\*;:{}=\-_`~()]/g,' ').trim();
            if(quickwords[shardWord]){
              shardWords[shardWordIndex] = quickwords[shardWord.trim()];
              foundQuickWord = true;
            }
          }
          if(foundQuickWord){
            const affectedShards: AffectedShards[] = [];
            affectedShards.push({
              shard_id: shardId,
              old_text: originalValue,
              new_text: shardWords.join(' '),
            });
            formatAndSendCorrection(affectedShards);

          } else if (multiWordEdit?.affectedShards?.length) {
            formatMultiWordCorrection();
            formatAndSendCorrection(multiWordEdit?.affectedShards)
          } else {
            const affectedShards: AffectedShards[] = [];
            affectedShards.push({
              shard_id: shardId,
              old_text: originalValue,
              new_text: removeZeroWidthSpace(shardElement.textContent),
            });
            formatAndSendCorrection(affectedShards)
          }
          moveCaretOutOfEditor()
        }
        break;
      default:
        break;
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event: any) => {
    const selection = (window as any)?.getSelection();
    let selectionAnchor = selection?.anchorNode;
    let anchorOffset = selection?.anchorOffset;
    // if we are at position 1 in the text node and it is not the first shard
    // then we need to move the caret to the end of the previous shard word's text
    if(selectionAnchor.nodeType === Node.TEXT_NODE
    && anchorOffset === 1
    && selectionAnchor.parentElement.parentElement !== selectionAnchor.parentElement.parentElement.parentElement.firstElementChild){
      moveCaretToPreviousWord(selectionAnchor)
    }
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    logger.info({
      methodName: '** handleClick',
      message: `element ` + element.toString(),
    }, false);
    let newElement = null;
    if (element?.attributes?.['data-type']?.value === 'shard') {
      newElement = element;
    } else if (element?.parentNode?.attributes?.['data-type']?.value === 'shard') {
      newElement = element.parentNode;
    }
    setNewShardFocus(newElement)
  };

  function getSelectedShardWords(shard: any, startWord?: any, endWord?: any) {
    let selectedWords = shard.querySelectorAll('span[data-type="shard-word"]');
    let startFound = !startWord;
    let selectedShardWords = [];
    for (let word of selectedWords) {
      if (!startFound) {
        startFound = word === startWord
      }
      if (startFound) {
        selectedShardWords.push(word)
      }
      if (word === endWord) {
        break;
      }
    }
    return selectedShardWords;
  }
  function reduceSelectionRange(highlightedShards: any) {
    const selectedShardWordsLength = highlightedShards.selectedShardWords.length;
    if (selectedShardWordsLength <= MAX_WORD_SELECTION) {
      return;
    }
    const range = document.createRange();
    if (highlightedShards.isLeftToRight) {
      let newEnd = highlightedShards.selectedShardWords[MAX_WORD_SELECTION - 1];
      range.setStart(highlightedShards.leftElement, highlightedShards.leftOffset);
      range.setEnd(newEnd, newEnd.childNodes.length);
    } else {
      let newStart = highlightedShards.selectedShardWords[selectedShardWordsLength - MAX_WORD_SELECTION];
      if (newStart.childNodes) {
        // position one to start at beginning of the first word
        // shard words start with spaces
        range.setStart(newStart.childNodes[0], 1);
      } else {
        range.setStart(newStart, 0);
      }
      range.setEnd(highlightedShards.rightElement, highlightedShards.rightOffset);
    }
    const selection: CustomSelection = window.getSelection() as CustomSelection;
    selection!.removeAllRanges();
    selection!.addRange(range);
  }

  function highlighIsLeftToRight(selection: CustomSelection) {
    let isLeftToRight = false;
    if (selection?.type === 'Range') {
      let position = selection?.anchorNode?.compareDocumentPosition(selection.focusNode)
      if (!position && selection.anchorOffset > selection?.focusOffset
        || position === Node.DOCUMENT_POSITION_PRECEDING) {
        isLeftToRight = false;
      } else {
        isLeftToRight = true;
      }
    }
    return isLeftToRight;
  }
  function findShardAndWordParents(element: any, focusOffset: number, isLeftToRight = true, isEnd = false) {
    let searchElement = element;
    let foundWord;
    let foundShard;
    try {
      while (searchElement?.attributes?.['data-type']?.value !== 'shard') {
        if (searchElement?.attributes?.['data-type']?.value === 'shard-word') {
          foundWord = searchElement
          foundShard = searchElement.parentElement;
          break;
        }
        if (isLeftToRight && isEnd) {
          if (searchElement.parentNode.id === searchElement.id) {
            searchElement = searchElement.previousElementSibling;
            break;
          }
        }
        if (searchElement.id === 'rsw-cts-editor') {
          break;
        }
        searchElement = searchElement.parentElement;
      }
      if (!foundShard && searchElement?.attributes?.['data-type']?.value !== 'shard') {
        logger.error({
          methodName: '** findShardAndWordParents',
          message: `unable to find shardWord for element ` + element.toString(),
        }, false);
        foundShard = null;
      }
    } catch (error: any) {
      logger.error({
        methodName: '** findShardAndWordParents',
        message: `failed searching from element ${element.toString()} with error ${error.message}`,
        errorStack: error.stack,
      }, false);
    }
    return {
      foundWord,
      foundShard,
    }
  }
  function getHighlightedShards(selection: any) {
    if(selection.type !== 'Range'){
      return {}
    }
    let isLeftToRight = highlighIsLeftToRight(selection)
    let leftElement = isLeftToRight ? selection?.anchorNode : selection?.focusNode;
    let rightElement = isLeftToRight ? selection?.focusNode : selection?.anchorNode;
    let leftOffset = isLeftToRight ? selection?.anchorOffset : selection?.focusOffset;
    let rightOffset = isLeftToRight ? selection?.focusOffset : selection?.anchorOffset;
    let startShard = null;
    let endShard = null;
    let startWord = null;
    let endWord = null;
    let selectedShards = [];
    let selectedShardWords: any[] = [];
    let startWordOffset = 0;
    let endWordOffset = 0;

    try {
      let foundParents = findShardAndWordParents(leftElement, leftOffset, isLeftToRight);
      startShard = foundParents?.foundShard;
      startWord = foundParents?.foundWord;

      let foundEndParents = findShardAndWordParents(rightElement, rightOffset, isLeftToRight, true);
      endShard = foundEndParents.foundShard
      endWord = foundEndParents.foundWord
      if (isLeftToRight && rightOffset === 1) {
        // don't select the word if only the zero width space char was the focus
        if (endWord.previousElementSibling) {
          endWord = endWord.previousElementSibling
        } else if (endShard.previousElementSibling) {
          endShard = endShard.previousElementSibling;
          endWord = endShard.lastElementChild;
        }
      }
      // Start Word Offset
      if (startWord.childNodes.length < 2) {
        startWordOffset = leftOffset;
      } else {
        for (let childNode of startWord.childNodes) {
          if (childNode === leftElement) {
            startWordOffset += leftOffset;
            break;
          }
          startWordOffset += childNode.innerText.length;
        }
      }
      if (endWord.childNodes.length < 2) {
        endWordOffset = rightOffset;
      } else {
        for (let childNode of endWord.childNodes) {
          if (childNode === leftElement) {
            endWordOffset += leftOffset;
            break;
          }
          endWordOffset += childNode.innerText.length;
        }
      }
      if (startShard && endShard) {
        selectedShards.push(startShard)
        selectedShardWords = getSelectedShardWords(startShard, startWord, endWord)
      }
      if (startShard !== endShard) {
        let searchShard = startShard.nextElementSibling;
        while (searchShard
          && searchShard !== endShard) {
          selectedShards.push(searchShard)
          selectedShardWords = [...selectedShardWords, ...getSelectedShardWords(searchShard)];
          searchShard = searchShard.nextElementSibling
        }
        selectedShards.push(endShard)
        selectedShardWords = [...selectedShardWords, ...getSelectedShardWords(endShard, null, endWord)]
      }
    } catch (error: any) {
      logger.error({
        methodName: '** getHighlightedShards',
        message: error.message,
        errorStack: error.stack,
      }, false);
    }

    return {
      selection,
      selectedShards,
      selectedShardWords,
      isLeftToRight,
      leftElement,
      leftOffset,
      rightElement,
      rightOffset,
      startWordOffset,
      endWordOffset,
    }
  }

  const handleMouseUp = (event: any) => {
    const selection: CustomSelection = window.getSelection() as CustomSelection;
    if (selection?.type === 'Range') {
      const shardSelection = getHighlightedShards(selection)
      if(shardSelection?.selection){
        logger.native('** handleMouseUp ',shardSelection);
        reduceSelectionRange(shardSelection);
      }
    }
  }
  function replaceWordInShardText(shard: CaptionShard, wordId: number, newText: string){
    // build shard text from each word
    // substitute word text for matching word id with new text
    let newShardText = ''
    newText = removeZeroWidthSpace(newText);
    for (let shardWord of shard.shardWords) {
      if (shardWord.wordId === wordId) {
        if(newShardText.length
        && newShardText.slice(-1) !== ' '
        && newText.charAt(0) != ' '){
          newText = ' ' + newText;
        }
        newShardText += newText
      } else {
        newShardText += removeZeroWidthSpace(shardWord.wordText)
      }
    }
    newShardText = newShardText.trim();
    return newShardText;
  }
  const handleCorrectionMenuClick = (shardId: number, wordId: number, replaceText: string, newText: string) => {
    logger.info({
      methodName: '** handleCorrectionMenuClick',
      parameters: {
        shardId,
        wordId,
        replaceText,
        newText
      },
    }, false);
    // get the captionShard
    let shard = captions[shardId]
    // build shard text from each word
    // substitute word text for matching word id with new text
    if(PUNCTUATION.includes(replaceText.slice(-1))){
      newText += replaceText.slice(-1);
    }
    let newShardText = replaceWordInShardText(shard, wordId, newText)
    let originalValue = shard.formattedText;
    const affectedShards: AffectedShards[] = [];
    affectedShards.push({
      shard_id: shardId,
      old_text: originalValue,
      new_text: newShardText,
    });
    formatAndSendCorrection(affectedShards);
    moveCaretOutOfEditor();
  }

  function handleShardWordContextMenu(event: any) {
    const element = event.target;
    logger.info({
      methodName: '** handleShardWordContextMenu',
      message: element.toString(),
    }, false);
    if (element.attributes?.['data-type']?.value === 'shard-word'
    && !elementIsBeyondEditableLimit(element)) {
      const selectedRange = document.createRange();
      // position one to start at beginning of the first word
      // shard words start with spaces

      selectedRange.setStart(element.childNodes[0], 1);
      selectedRange.setEnd(element.lastChild, element.lastChild.length);
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(selectedRange);
    }
  }
  const appendCaption = (captionText: string) => {
    let affectedShard = {
      shard_id: -1, // default to insert at beginning
      old_text: '',
      new_text: captionText,
    }
    if(Object.keys(captions).length){
      // append text to last shard
      const lastShard = captions[Object.keys(captions).length-1];
      affectedShard.shard_id = lastShard.shardId;
      affectedShard.old_text = lastShard.formattedText;
      affectedShard.new_text = affectedShard.old_text + ' ' + captionText;
    }
    formatAndSendCorrection([affectedShard]);
  }

  return {
    appendCaption,
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleKeyup,
    handleClick,
    handleCorrectionMenuClick,
    handleMouseUp,
    handleShardWordContextMenu,
  };

}