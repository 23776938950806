import packageJson from '../../package.json';
import { config } from 'shared/config/cts.config';

let appVersion = packageJson.version;

enum LogLevelType {
  INFO = 'info',
  ERROR = 'error',
  WARN = 'warn',
}
export class LogData {
  constructor(
    public callId?: string,
    public methodName?: string,
    public message?: string,
    public errorStack?: string,
    public parameters?: Record<string, any>,
    public newAndOldValues?: Record<string, any>,
    public handoffType?: string,
    public callLength?: number
  ) {
    this.callId = callId;
    this.methodName = methodName;
    this.message = message;
    this.errorStack = errorStack;
    this.parameters = parameters;
    this.newAndOldValues = newAndOldValues;
    this.handoffType = handoffType;
    this.callLength = callLength;
  }
}

class DynatraceLogsType extends LogData {
  private agentNumber?: string;
  private sessionLoginId?: string;
  private dateTime?: string;
  private appName?: string;
  private environment?: string;
  private appVersion?: string;
  private region?: string;
  constructor(public logLevelType: LogLevelType, logData: LogData) {
    super(
      logData.callId,
      logData.methodName,
      logData.message,
      logData.errorStack,
      logData.parameters,
      logData.newAndOldValues,
      logData.handoffType,
      logData.callLength
    );
    let user = getUser();
    this.agentNumber = user?.agentNumber;
    this.sessionLoginId = user?.sessionLoginId;
    this.dateTime = new Date().toISOString();
    this.logLevelType = logLevelType;
    this.environment = process.env.REACT_APP_ENVIRONMENT || 'local';
    this.appName = process.env.DT_RELEASE_PRODUCT || 'Captioner';
    this.region = process.env.REACT_APP_AWS_REGION;
    this.appVersion = appVersion;
  }
  formatLog() {
    for (let key in this) {
      if (this[key] === undefined) {
        delete this[key];
      }
    }
    return JSON.parse(JSON.stringify(this));
  }
}
function getToken() {
  let storedTokens = localStorage.getItem('tokenInfo') || '';
  let storedTokenObject = JSON.parse(storedTokens);
  return storedTokenObject?.accessToken;
}
function getUser() {
  let storedUser = localStorage.getItem('user');
  if (storedUser) {
    return JSON.parse(storedUser);
  }
}

let persistentCallId: string | undefined;

export function setPersistentCallId(callId: string) {
  persistentCallId = callId;
}

function getPersistentCallId() {
  return persistentCallId;
}

async function postLogToServer(logData: DynatraceLogsType) {
  let response;
  let userToken = getToken();
  if (!userToken) {
    console.error(
      {
        logLevelType: LogLevelType.ERROR,
        methodName: 'postLogToServer',
        parameters: { logData },
        message: `Missing user token, cannot post.`,
      },
      false
    );
    return;
  }
  try {
    let body = JSON.stringify(logData);
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);

    response = await fetch(`${config.REACT_APP_CAPTIONER_SERVICES_API}/logs`, {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      body: body,
    });
    if (response.status !== 200) {
      throw new Error(`post /logs returned status code ${response?.status}`);
    }
  } catch (error: any) {
    console.error({
      logLevelType: LogLevelType.ERROR,
      methodName: 'postLogToServer',
      parameters: { logData },
      message: `Failed with error message ${error.message} `,
      errorStack: error.stack,
    });
  }
}
function log(logData: DynatraceLogsType, postToServer: boolean = true) {
  if (!logData.callId && getPersistentCallId()) {
    logData.callId = getPersistentCallId();
  }
  // Output to Browser
  console[logData.logLevelType](logData.formatLog());
  // Output to ClowdWatch + Dynatrace
  if (postToServer) {
    postLogToServer(logData);
  }
}

async function info(logData: LogData, postToServer: boolean = true) {
  if (!logData.callId && getPersistentCallId()) {
    logData.callId = getPersistentCallId();
  }
  log(new DynatraceLogsType(LogLevelType.INFO, logData), postToServer);
}
async function warn(logData: LogData, postToServer: boolean = true) {
  if (!logData.callId && getPersistentCallId()) {
    logData.callId = getPersistentCallId();
  }
  log(new DynatraceLogsType(LogLevelType.WARN, logData), postToServer);
}
async function error(logData: LogData, postToServer: boolean = true) {
  if (!logData.callId && getPersistentCallId()) {
    logData.callId = getPersistentCallId();
  }
  log(new DynatraceLogsType(LogLevelType.ERROR, logData), postToServer);
}
async function native(...logData: any[]) {
  console.log(...logData);
}

export default {
  info,
  warn,
  error,
  native,
  setPersistentCallId,
};
