import {
  current,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import {
  CaptionShard,
} from 'shared/hooks/axon/gateway.types';
import logger from 'services/logger';

interface Captions {
  value: Record<number, CaptionShard>,
  buffer: CaptionShard[],
  bufferingCaptions: boolean,
}

const initialState: Captions = {
  value: {},
  buffer: [],
  bufferingCaptions: false,
}

type CaptionUserEditingPayload = {
  shardId: number;
  userEditing: boolean;
}

type CaptionShardWordTextPayload = {
  shardId: number,
  wordIndex: number,
  wordText: string,
}

const captionsSlice = createSlice({
  name: 'captions',
  initialState: initialState,
  reducers: {
    setCaptionFromSavvy: (state, action: PayloadAction<CaptionShard>) => {
      state.value[action.payload.shardId] = action.payload;
    },
    setCaption: (state, action: PayloadAction<CaptionShard>) => {
      state.value[action.payload.shardId] = action.payload;
    },
    setCaptions: (state, action: PayloadAction<Record<number, CaptionShard>>) => {
      state.value = action.payload;
    },    
    clearCaptions: (state,) => {
      state.value = {};
    },
    trimCaptions: (state, action: PayloadAction<any>) => {
      let trimToShardId = parseFloat(action.payload.shardId);
      let trimToWordId = parseFloat(action.payload.wordId);
      let captions = JSON.parse(JSON.stringify(current(state).value));
      let shardKeys = Object.keys(captions);
      let shardCount = shardKeys.length;
      for(let index = 0; index < shardCount; ++index){
        let shard = captions[shardKeys[index]];
        if(!shard){
          logger.warn({
            methodName: `trimCaptions`,
            message: `** failed to find shard [${shardKeys[index]}] at index [${index}]`
          }, false);
          return;
        }
        if(shard.shardId === trimToShardId){
          if(!trimToWordId){
            logger.info({
              methodName: `trimCaptions`,
              message: `** deleting shard [${shard.shardText}]`
            }, false);
            delete captions[shardKeys[index]];
          } else {
            let shardToTrim = captions[shardKeys[index]];
            for(let wordIndex = 0; wordIndex < shardToTrim.shardWords.length; ++wordIndex){
              if(shardToTrim.shardWords[wordIndex].wordId === trimToWordId){
                shardToTrim.shardWords = shardToTrim.shardWords.toSpliced(0, wordIndex+1);
                logger.info({
                  methodName: `trimCaptions`,
                  message: `** trim shard to word [${shardToTrim.shardWords.join()}]`
                }, false);
                break;
              }
            }
          }
          break;
        }
        logger.info({
          methodName: `trimCaptions`,
          message: `** deleting shard [${shard.shardText}]`
        }, false);
        delete captions[shardKeys[index]];
      }
      logger.info({
        methodName: `trimCaptions`,
        message: `** deleted ${shardCount - Object.keys(captions).length} shards`
      }, false);
      state.value = captions;
    },
    setCaptionUserEditing: (state, action: PayloadAction<CaptionUserEditingPayload>) => {
      if(current(state).value?.[action.payload.shardId]){
        state.value[action.payload.shardId].userEditing = action.payload.userEditing;
      }
    },
    setCaptionShardWordText: (state, action: PayloadAction<CaptionShardWordTextPayload>) => {
      if(current(state).value?.[action.payload.shardId]?.shardWords?.[action.payload.wordIndex]){
        state.value[action.payload.shardId].shardWords[action.payload.wordIndex].wordText = action.payload.wordText;
      }
    },
    setBufferingCaptions: (state, action: PayloadAction<boolean>) => {
      if(action.payload === false){
        let buffer = current(state).buffer;
        // copy buffer to state
        while(buffer.length > 0){

          const bufferedCaption = JSON.parse(JSON.stringify(buffer[buffer.length - 1]));
          if(bufferedCaption){
            state.value[bufferedCaption.shardId] = bufferedCaption;
            state.buffer = state.buffer.toSpliced(-1, 1);
            buffer = current(state).buffer;
          }
        }
      }
      state.bufferingCaptions = action.payload;
    }
  }
})

export const {
  setCaption,
  setCaptions,
  setCaptionFromSavvy,
  clearCaptions,
  setCaptionUserEditing,
  setCaptionShardWordText,
  setBufferingCaptions,
  trimCaptions,
} = captionsSlice.actions;

export default captionsSlice.reducer;